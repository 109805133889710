<template>
  <footer class="footer mt-6 has-background-primary has-text-light">
    <div class="content has-text-centered has-text-weight-bold">
      <p>NLV Media Transcoder. Developed by <a class="has-text-light" href="https://modernizd.com" target="_blank" rel="nofollow">Modernizd.</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar'
}
</script>

<style>

</style>