<template>
  <section class="hero is-primary mb-6">
    <div class="container">
      <div class="hero-body">
        <p class="title">
          {{ title }}
        </p>
        <p v-if="subTitle" class="subtitle">
          {{ subTitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: ['title', 'subTitle']
}
</script>

<style lang="scss" scoped>

</style>