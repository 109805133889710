<template>
  <div class="panel is-primary">
    <p class="panel-heading">Finished Game Footage</p>
    <div class="panel-block p-3">
      <div class="columns is-flex-grow-1">
        <div class="column games-column">
          <destination-options :destination.sync="destination"></destination-options>
          <ul>
            <li
              class="is-flex is-align-items-center is-justify-content-space-between pb-3 mb-3"
              v-for="game in games" :key="game.Key">
              <span class="is-size-7 has-text-weight-semibold pr-3">{{ game.Key | fileName }}</span>
              <b-button type="is-success is-light" @click="setCurrentVideo(game.url)" icon-right="play-circle-outline"></b-button>
            </li>
          </ul>
        </div>
        <div class="column">
          <video-player :video="currentVideo"></video-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DestinationOptions from './DestinationOptions';
import VideoPlayer from './VideoPlayer';

export default {
  name: 'FinishedFootage',
  components: {
    DestinationOptions,
    VideoPlayer
  },
  data () {
    return {
      destination: '',
      games: [],
      currentVideo: ''
    }
  },
  methods: {
    async getGames () {
      if (!this.destination) {
        return false;
      }
      const games = (await this.$http.get(`/destinations/${this.destination.split('/')[1]}`)).data;
      this.games = games;
    },
    setCurrentVideo (video) {
      this.currentVideo = video;
    }
  },
  watch: {
    async destination () {
      await this.getGames();
    }
  },
  filters: {
    fileName (value) {
      const raw = value.split('/');
      return raw[raw.length - 1];
    }
  }
}
</script>

<style lang="scss" scoped>
.games-column {
  max-height: 500px;
  overflow-y: scroll;
}

li {
  border-bottom: 1px solid hsl(0, 0%, 86%);
}
</style>