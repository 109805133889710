<template>
  <div class="home">
    <hero
      title="Welcome to NLV Media Transcoder"
      sub-title="To get started, either create a new job, or view your existing jobs below." />
    <div class="container is-max-widescreen p-4">
      <finished-footage></finished-footage>
      <jobs-table></jobs-table>
    </div>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import JobsTable from '@/components/JobsTable.vue'
import FinishedFootage from '@/components/FinishedFootage.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    JobsTable,
    FinishedFootage
  }
}
</script>
