<template>
  <div class="panel is-primary">
    <div class="panel-heading is-flex is-justify-content-space-between is-align-items-center">
      All Media Convert Jobs
      <b-button type="is-warning" icon-right="refresh" @click="getJobs">Refresh Jobs</b-button>
    </div>
    <b-table :data="jobs" :loading="loading" :sticky-header="true" height="500" :scrollable="true">
      <b-table-column field="destination" label="Destination" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-primary">{{ props.row.destination }}</b-tag>
      </b-table-column>

      <b-table-column field="fileName" label="File Name" v-slot="props">
        <p class="is-size-7 mt-1 has-text-weight-semibold">{{ props.row.fileName }}</p>
      </b-table-column>

      <b-table-column field="duration" label="Length" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-info is-light">{{ props.row.duration | humanize }}</b-tag>
      </b-table-column>

      <b-table-column field="startTime" label="Start Time" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-light">{{ props.row.startTime | time }}</b-tag>
      </b-table-column>

      <b-table-column field="totalTime" label="Total Time" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-warning is-light">{{ props.row.totalTime | humanize }}</b-tag>
      </b-table-column>
      
      <b-table-column field="percentComplete" label="Percent Complete" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-info is-light">{{ props.row.percentComplete }}</b-tag>
      </b-table-column>

      <b-table-column field="createdBy" label="Created By" v-slot="props">
        <b-tag class="has-text-weight-semibold" type="is-primary is-light">{{ props.row.createdBy }}</b-tag>
      </b-table-column>

      <b-table-column field="status" label="Status" centered v-slot="props">
        <b-tag class="has-text-weight-semibold" :type="props.row.status === 'COMPLETE' ? 'is-success is-light' : props.row.status === 'ERROR' ? 'is-danger is-light' : 'is-warning is-light'">{{ props.row.status }}</b-tag>
      </b-table-column>

      <b-table-column field="actions" label="Actions" centered v-slot="props">
        <b-button type="is-danger" v-if="props.row.actions.status !== 'COMPLETE' && props.row.actions.status !== 'ERROR' && props.row.actions.status !== 'CANCELED'" size="is-small" icon-right="close"  @click="cancelJob(props.row.actions.id)">Cancel Job</b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';

export default {
  name: "JobsTable",
  data() {
    return {
      loading: false,
      jobs: []
    };
  },
  async created () {
    await this.getJobs();
  },
  methods: {
    async getJobs () {
      this.loading = true;
      const jobs = (await this.$http.get('/jobs')).data;
      this.jobs = jobs.map(job => {
        return {
          id: job.Id,
          destination: this.getDestinationFolderName(job.Settings.OutputGroups[0].OutputGroupSettings.FileGroupSettings.Destination),
          fileName: this.getDestinationFileName(job.Settings.OutputGroups[0].OutputGroupSettings.FileGroupSettings.Destination),
          duration: job.OutputGroupDetails ? job.OutputGroupDetails[0].OutputDetails[0].DurationInMs : 0,
          startTime: job.Timing.StartTime,
          percentComplete: job.JobPercentComplete ? `${job.JobPercentComplete}%` : job.Status === 'COMPLETE' ? '100%' : 'N/A',
          createdBy: job.UserMetadata.Email,
          totalTime: this.calculateTotalTime(job.Timing.StartTime, job.Timing.FinishTime),
          status: job.Status,
          actions: {
            status: job.Status,
            id: job.Id
          }
        }
      });
      this.loading = false;
    },
    async cancelJob (jobId) {
      const job = (await this.$http.delete(`/jobs/${jobId}`)).data;
      console.log(job);
      console.log('Successfully canceled Job: ' + jobId);
      await this.getJobs();
    },
    getDestinationFolderName (destination) {
      const path = destination.split('/');
      return path[path.length - 2];
    },
    getDestinationFileName (destination) {
      const path = destination.split('/');
      return path[path.length - 1];
    },
    calculateTotalTime (startTime, finishTime) {
      if (!finishTime) {
        return 'N/A';
      }
      const start = dayjs(startTime);
      const finish = dayjs(finishTime);

      return finish.diff(start);
    }
  },
  filters: {
    time (value) {
      return dayjs(value).format('M/D/YYYY h:mmA');
    },
    humanize (value) {
      if (value === 'N/A') {
        return 'N/A';
      }
      return humanizeDuration(value, { maxDecimalPoints: 0, units: ['h', 'm', 's'] });
    }
  }
};
</script>

<style lang="scss" scoped>
.jobs-table-max-height {
  max-height: 500px;
  overflow-y: scroll;
}
</style>