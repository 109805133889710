import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './services/http';
import firebase from './services/firebase';
import Buefy from 'buefy';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Buefy);

Vue.config.productionTip = false

Vue.prototype.$http = http;

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});
