<template>
  <div class="c-video-player">
    <video ref="player" playsinline controls>
      <source :src="video" type="video/mp4" />
    </video>
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </div>
</template>

<script>
import Plyr from 'plyr';

export default {
  name: 'VideoPlayer',
  props: ['video'],
  data () {
    return {
      loading: false,
      player: null
    }
  },
  mounted () {
    this.renderVideo();
  },
  methods: {
    renderVideo () {
      this.player = new Plyr(this.$refs.player);

      this.player.on('loadstart', () => {
        this.loading = true;
      });

      this.player.on('canplay', () => {
        this.loading = false;
      });
    }
  },
  watch: {
    video () {
      this.player.source = {
        type: 'video',
        sources: [
          {
            src: this.video,
            type: 'video/mp4',
            size: 1080
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.plyr.io/3.6.7/plyr.css');

.c-video-player {
  position: relative;
}
</style>