import axios from 'axios';
import firebase from './firebase';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_API_URL}/api`,
  timeout: 10000
});

http.interceptors.request.use(async (config) => {
  if (firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken(true);
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default http;
