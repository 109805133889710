<template>
  <b-field label="Destination">
    <b-dropdown
      :disabled="destinations.length === 0"
      :value="destination"
      aria-role="list"
      :scrollable="true"
      @change="$emit('update:destination', $event)"
      expanded>
      <template #trigger>
        <b-button
          type="is-light"
          icon-right="menu-down"
          expanded>
          {{ destination || 'Select Destination' }}
        </b-button>
      </template>
      <b-dropdown-item custom aria-role="listitem">
        <b-input
          v-model="destinationSearchTerm"
          placeholder="Search Destinations"
          expanded />
      </b-dropdown-item>
      <b-dropdown-item v-for="d in filteredDestinations" :value="d.Prefix" :key="d.Prefix" aria-role="listitem">
        {{ d.Prefix }}
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>

<script>
export default {
  name: 'DestinationOptions',
  props: ['destination'],
  data () {
    return {
      destinationSearchTerm: '',
      destinations: []
    }
  },
  async created () {
    await this.getDestinations();
  },
  computed: {
    filteredDestinations () {
      return this.destinations.filter((destination) => destination.Prefix.toLowerCase().indexOf(this.destinationSearchTerm.toLowerCase()) >= 0);
    }
  },
  methods: {
    async getDestinations () {
      this.destinations = (await this.$http.get('/destinations')).data
    },
    emitValue (key, value) {
      this.$emit(`update:${key}`, value)
    }
  }
}
</script>

<style>

</style>