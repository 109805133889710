<template>
  <b-navbar type="is-primary">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          class="logo"
          src="https://nlvproductions.com/images/nlv-logo-white.svg"
          alt="The Buefy Logo" />
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            tag="router-link"
            to="/job"
            type="is-success is-light"
            icon-right="plus-circle-outline">Create Jobs</b-button>
        </div>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            type="is-danger is-light"
            @click="signOut"
            icon-right="logout">Sign Out</b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import firebase from '../services/firebase';
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'NavigationBar',
  props: ['user'],
  methods: {
    async signOut () {
      try {
        await firebase.auth().signOut();
        this.$router.push('/login');
      } catch (error) {
        Toast.open({
          type: 'is-danger',
          message: 'There was a problem when trying to sign out.'
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: 4rem;
  max-height: 4rem;
}
</style>